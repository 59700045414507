import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Space } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  href: string;
  children: ReactNode;
}

const NewTabLink = ({ href, children }: Props) => {
  return (
    <Anchor href={href} target="_blank" sx={{ display: "inline-flex", alignItems: "baseline" }}>
      {children}
      <Space w={4} />
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Anchor>
  );
};

export default NewTabLink;
