import { useMemo } from "react";

import { useGateway } from "@/contexts/GatewayContext";
import { NO_SECTION_LABEL } from "@/features/stores/constants";
import { useApiQuery } from "@/hooks/useApiQuery";
import { groupBy } from "@/utils/groupBy";

export const USE_ORGANIZATIONS_QUERY_KEY = "company/organizations";

export interface Section {
  id: number;
  label: string;
  value: string;
  displayOrderId: string;
}

export interface Store {
  id: number;
  label: string;
  value: string;
  sectionId?: number | null;
  displayOrderId: string;
}

export interface StoreWithGroup extends Store {
  group: string;
}

export interface Role {
  id: number;
  label: string;
  value: string;
}

const createGroupedStores = (stores: Store[], sections: Section[]) => {
  return groupBy(
    stores.map((store) => ({
      ...store,
      group: sections.find((section) => section.id === store.sectionId)?.label || NO_SECTION_LABEL,
    })) || [],
    (store) => String(store.sectionId)
  );
};

const createGroupedStoresArray = (groupedStores: Map<string, StoreWithGroup[]>) =>
  [...groupedStores.entries()].reduce(
    (acc: StoreWithGroup[], [_sectionId, stores]) => acc.concat(stores),
    []
  );

export const useOrganizations = () => {
  const gateway = useGateway();

  const { data, ...others } = useApiQuery([USE_ORGANIZATIONS_QUERY_KEY], () =>
    gateway.adminApiClient.getAdminCompanyOrganization()
  );

  const company = useMemo(() => {
    return data?.currentCompany;
  }, [data]);

  const sections = useMemo<Section[]>(() => {
    if (!data) return [];
    return data.allSections.map((section) => ({
      id: section.id,
      label: section.name,
      value: String(section.id),
      displayOrderId: section.displayOrderId,
    }));
  }, [data]);

  const stores = useMemo<Store[]>(
    () =>
      data?.allStores.map((store) => ({
        id: store.id,
        label: store.name,
        value: String(store.id),
        sectionId: store.sectionId ? store.sectionId : null,
        displayOrderId: store.displayOrderId,
      })) || [],
    [data]
  );

  const groupedStores = useMemo(
    () => createGroupedStores(stores || [], sections),
    [stores, sections]
  );

  const groupedStoresArray = useMemo(
    () => createGroupedStoresArray(groupedStores),
    [groupedStores]
  );

  const roles = useMemo<Role[]>(
    () =>
      data?.allAdminPredefinedRoles.map((role) => ({
        id: role.id,
        label: role.name,
        value: String(role.id),
      })) || [],
    [data]
  );

  const selectableRoles = useMemo<Role[]>(
    () =>
      data?.currentAdmin.selectablePredefinedRoles.map((role) => ({
        id: role.id,
        label: role.name,
        value: String(role.id),
      })) || [],
    [data]
  );

  const currentAdmin = data?.currentAdmin;

  const operableSections = useMemo<Section[]>(() => {
    if (!currentAdmin) return [];
    if (currentAdmin.canOperateAllStores) return sections;
    return currentAdmin.operableSections.map((section) => ({
      id: section.id,
      label: section.name,
      value: String(section.id),
      displayOrderId: section.displayOrderId,
    }));
  }, [currentAdmin, sections]);

  const operableStores = useMemo<Store[]>(() => {
    if (!currentAdmin) return [];
    if (currentAdmin.canOperateAllStores) return stores;

    const operableSectionsStores = currentAdmin.operableSections.reduce((acc: Store[], section) => {
      const targetStores = groupedStores.get(String(section.id));
      if (!targetStores) return acc;
      return acc.concat(
        targetStores.map((targetStore) => {
          const { group, ...others } = targetStore;
          return others;
        })
      );
    }, []);

    const operableStores = currentAdmin.operableStores.map((store) => ({
      id: store.id,
      label: store.name,
      value: String(store.id),
      sectionId: store.sectionId,
      displayOrderId: store.displayOrderId,
    }));

    return operableSectionsStores.concat(operableStores).sort((a, b) => {
      return a.displayOrderId < b.displayOrderId ? 1 : -1;
    });
  }, [currentAdmin, stores, groupedStores]);

  const operableGroupedStores = useMemo(
    () => createGroupedStores(operableStores, sections),
    [operableStores, sections]
  );
  const operableGroupedStoresArray = useMemo(
    () => createGroupedStoresArray(operableGroupedStores),
    [operableGroupedStores]
  );
  const operableGroupedStoresArrayForSelect = useMemo(
    () =>
      operableGroupedStoresArray.map((store) => ({
        id: store.id,
        label: store.label,
        value: store.value,
        group: store.group,
      })),
    [operableGroupedStoresArray]
  );
  const operableStoresSections = useMemo(
    () =>
      Array.from(operableGroupedStores.entries())
        .flatMap(([value]) => sections.find((section) => section.value === value) ?? [])
        .sort((a, b) => {
          return a.displayOrderId > b.displayOrderId ? 1 : -1;
        }),
    [operableGroupedStores, sections]
  );

  return {
    ...others,
    company,
    sections,
    groupedStores,
    groupedStoresArray,
    stores,
    roles,
    selectableRoles,
    currentAdmin,
    operableSections,
    operableStores,
    operableGroupedStores,
    operableGroupedStoresArray,
    operableGroupedStoresArrayForSelect,
    operableStoresSections,
  };
};
