import { COLORS } from "@/styles/colors";

import { AppShell } from "@mantine/core";
import { ReactNode } from "react";

import GlobalNavigation from "@/components/organisms/GlobalNavigation";

interface Props {
  children: ReactNode;
}

const DefaultLayout = ({ children }: Props) => {
  return (
    <AppShell fixed header={<GlobalNavigation />} sx={{ backgroundColor: COLORS.gray100 }}>
      {children}
    </AppShell>
  );
};

export default DefaultLayout;
