import { APP_ENV, APP_ENVS } from "@/utils/env";

const API_HOSTS = {
  [APP_ENVS.DEV]: "http://localhost:23000",
  [APP_ENVS.STG]: "https://api.stg-hrms.rakushifu.com",
  [APP_ENVS.QA]: "https://api.stg-hrms.rakushifu.com",
  [APP_ENVS.PROD]: "https://api.hrms.rakushifu.com",
  [APP_ENVS.TEST]: "",
} as const;

const SENTRY_DSNS = {
  [APP_ENVS.DEV]: "",
  [APP_ENVS.STG]:
    "https://e9e75fed79ab45fbae5d44c3e7e76c3d@o149839.ingest.sentry.io/4504831247843328",
  [APP_ENVS.QA]:
    "https://e9e75fed79ab45fbae5d44c3e7e76c3d@o149839.ingest.sentry.io/4504831247843328",
  [APP_ENVS.PROD]:
    "https://7ed81f5acca143899c5e46af896e924a@o149839.ingest.sentry.io/4504848052977664",
  [APP_ENVS.TEST]: "",
};

export const API_HOST = API_HOSTS[APP_ENV];
export const SENTRY_DSN = SENTRY_DSNS[APP_ENV];
