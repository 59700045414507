import "@/styles/globals.css";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Global, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Head from "next/head";
import { useState } from "react";
import { pdfjs } from "react-pdf";

import ErrorBoundary from "@/components/ErrorBoundary";
import { getDefaultLayout } from "@/components/layouts";
import { AuthProvider } from "@/contexts/AuthContext";
import { GatewayProvider } from "@/contexts/GatewayContext";
import { useClientAuth } from "@/gateways/authStore";
import { createAdminApiClient, createCommonApiClient } from "@/infrastructures/apiClient";
import { AppPropsWithLayout } from "@/types";

config.autoAddCss = false;
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const globalFontFamily =
  'Avenir, "Helvetica Neue", Helvetica, Arial, "Hiragino Kaku Gothic ProN", YuGothic';

function MyApp({ Component, pageProps }: AppPropsWithLayout<{ dehydratedState: any }>) {
  const auth = useClientAuth();
  const adminApiClient = createAdminApiClient(auth);
  const commonApiClient = createCommonApiClient(auth);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const { dehydratedState, ...props } = pageProps;
  const getLayout = Component.getLayout ?? getDefaultLayout;
  return (
    <>
      <Head>
        <title>らくしふ労務管理</title>
        <meta name="robots" content="noindex" />
      </Head>
      <ErrorBoundary>
        <AuthProvider adminApiClient={adminApiClient}>
          <GatewayProvider adminApiClient={adminApiClient} commonApiClient={commonApiClient}>
            <QueryClientProvider client={queryClient}>
              <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                  fontFamily: globalFontFamily,
                  black: "#444",
                  headings: {
                    fontFamily: globalFontFamily,
                    sizes: {
                      h2: {
                        fontSize: 20,
                        lineHeight: 1,
                      },
                      h3: {
                        fontSize: 14,
                        lineHeight: 1,
                      },
                    },
                  },
                }}
              >
                <ModalsProvider>
                  <NotificationsProvider position="top-center">
                    <ReactQueryDevtools />
                    <Global
                      styles={{
                        body: {
                          fontSize: 14,
                          fontFamily: globalFontFamily,
                        },
                      }}
                    />
                    <Hydrate state={dehydratedState}>{getLayout(<Component {...props} />)}</Hydrate>
                  </NotificationsProvider>
                </ModalsProvider>
              </MantineProvider>
            </QueryClientProvider>
          </GatewayProvider>
        </AuthProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
