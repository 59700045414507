import { ReactElement } from "react";

import DefaultLayout from "@/components/layouts/DefaultLayout";

export const getDefaultLayout = (page: ReactElement) => {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getPlainLayout = (page: ReactElement) => {
  return <>{page}</>;
};
