import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useRouter } from "next/router";

export const useApiQuery = <
  QueryKey extends [string, Record<string, unknown>?],
  QueryFnData,
  Error extends AxiosError,
  Data = QueryFnData
>(
  queryKey: QueryKey,
  fetcher: (params: QueryKey[1]) => Promise<AxiosResponse<QueryFnData>>,
  options?: Omit<UseQueryOptions<QueryFnData, Error, Data, QueryKey>, "queryKey" | "queryFn">
) => {
  const { initialData, ...rest } = options || {};
  const router = useRouter();
  return useQuery(
    queryKey,
    async () => {
      const { data } = await fetcher(queryKey[1]);
      return data;
    },
    {
      enabled: typeof window !== "undefined" && router.isReady,
      useErrorBoundary: (error) => !!(error.response?.status && error.response.status >= 500),
      ...rest,
    }
  );
};
