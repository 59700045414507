import { COLORS } from "@/styles/colors";

import { Button, ButtonProps } from "@mantine/core";
import { createPolymorphicComponent } from "@mantine/utils";
import { forwardRef } from "react";

const _WhiteButton = forwardRef<HTMLButtonElement, ButtonProps>(({ sx, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      variant="white"
      color="dark"
      sx={{
        borderColor: COLORS.gray300,
        fontWeight: "normal",
        ...sx,
      }}
      {...props}
    />
  );
});

_WhiteButton.displayName = "WhiteButton";
const WhiteButton = createPolymorphicComponent<"button", ButtonProps>(_WhiteButton);
export default WhiteButton;
