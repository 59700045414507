import { COLORS } from "@/styles/colors";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Center, Text } from "@mantine/core";
import { useRouter } from "next/router";
import { forwardRef } from "react";

interface Props {
  label: string;
  items?: { href: string }[];
}

const MenuControl = forwardRef<HTMLDivElement, Props>(({ label, items, ...others }, ref) => {
  const router = useRouter();
  const active = items?.some((item) => router.pathname.startsWith(item.href));
  return (
    <Center
      ref={ref}
      sx={{
        height: "100%",
        fontSize: 14,
        cursor: "pointer",
        ...(active
          ? {
              color: COLORS.rakushifuBlue,
              fontWeight: "bold",
              borderBottom: `2px solid ${COLORS.rakushifuBlue}`,
              marginBottom: -4,
            }
          : {}),
      }}
      px={16}
      role="menu"
      aria-label={label}
      {...others}
    >
      <Box sx={active ? { marginTop: -2 } : undefined}>
        <Text span mr={4}>
          {label}
        </Text>
        <FontAwesomeIcon icon={faCaretDown} />
      </Box>
    </Center>
  );
});
MenuControl.displayName = "MenuControl";

export default MenuControl;
