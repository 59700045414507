import { AdminApi, CommonApi, Configuration } from "@rakushifu/hrms-modules/dist/openapi";
import axios, { AxiosInstance, AxiosRequestTransformer, AxiosResponseTransformer } from "axios";
import camelcaseKeys from "camelcase-keys";
import ServerFormData from "form-data";
import snakecaseKeys from "snakecase-keys";

import { HRMS_AUTH_HEADER_KEYS } from "@/constants";
import { Auth } from "@/gateways/authStore";
import { API_HOST } from "@/utils/config";

const transformRequestCamelKeyToSnakeKey: AxiosRequestTransformer = (data: any) => {
  if (
    data instanceof ServerFormData ||
    (typeof window !== "undefined" && data instanceof FormData)
  ) {
    return data;
  }
  if (data) {
    return JSON.stringify(snakecaseKeys(JSON.parse(data), { deep: true }));
  }
  return data;
};

const transformResponseSnakeKeyToCamelKey: AxiosResponseTransformer = (data: any) => {
  return camelcaseKeys(data, { deep: true });
};

const conf = new Configuration({
  basePath: "",
  baseOptions: {},
});

class ExtendedAdminApi extends AdminApi {
  constructor(configuration: Configuration, basePath: string, axios: AxiosInstance) {
    super(configuration, basePath, axios);
  }

  async signIn(params: { email: string; password: string }) {
    const res = await this.signInAsAdmin(params);

    return {
      ...res.data,
      accessToken: res.headers[HRMS_AUTH_HEADER_KEYS.accessToken],
      uid: res.headers[HRMS_AUTH_HEADER_KEYS.uid],
      client: res.headers[HRMS_AUTH_HEADER_KEYS.client],
    };
  }
}

export const createAdminApiClient = (auth?: Auth) => {
  const axiosClient = createAxiosClient(auth);
  return new ExtendedAdminApi(conf, API_HOST, axiosClient);
};

export const createCommonApiClient = (auth?: Auth) => {
  const axiosClient = createAxiosClient(auth);
  return new CommonApi(conf, API_HOST, axiosClient);
};

const createAxiosClient = (auth?: Auth) => {
  const axiosClient = axios.create({
    transformRequest: [
      transformRequestCamelKeyToSnakeKey,
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      transformResponseSnakeKeyToCamelKey,
    ],
    headers: auth
      ? {
          [HRMS_AUTH_HEADER_KEYS.accessToken]: auth.accessToken,
          [HRMS_AUTH_HEADER_KEYS.uid]: auth.uid,
          [HRMS_AUTH_HEADER_KEYS.client]: auth.client,
        }
      : {},
  });

  axiosClient.interceptors.request.use(async (config) => {
    if (typeof window !== "undefined" && config.data instanceof FormData && config.url) {
      // 従業員の外国籍情報の更新
      if (
        config.method === "patch" &&
        /\/v1\/admin\/employees\/\d+\/foreign_nationality$/.test(config.url)
      ) {
        for (const key of ["nationality", "resident_status"]) {
          const value = config.data.get(key);
          if (value instanceof File && value.text) {
            const text = JSON.parse(await value.text());
            config.data.set(key, text);
          }
        }
      }
    }
    return config;
  });

  return axiosClient;
};
