import { COLORS } from "@/styles/colors";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Center, Menu } from "@mantine/core";
import Link from "next/link";
import { ReactNode } from "react";

interface Props {
  onClickItem?: () => void;
  icon: IconDefinition;
  href?: string;
  children: ReactNode;
}

const MenuItem = ({ onClickItem, icon, href = "", children }: Props) => {
  if (href.length === 0 && !onClickItem) return null;
  return (
    <Link href={href}>
      <Menu.Item
        onClick={onClickItem}
        sx={{
          padding: "8px",
          borderRadius: "8px",
          ":hover": {
            backgroundColor: `${COLORS.rakushifuBluePale} !important`,
            ".icon-wrapper": {
              backgroundColor: COLORS.rakushifuBlue,
              svg: {
                color: "white",
              },
            },
          },
        }}
        icon={
          <Center
            component="span"
            className="icon-wrapper"
            sx={{
              borderRadius: "50%",
              backgroundColor: COLORS.gray200,
              width: "32px",
              height: "32px",
            }}
          >
            <FontAwesomeIcon icon={icon} size="sm" />
          </Center>
        }
      >
        {children}
      </Menu.Item>
    </Link>
  );
};

export default MenuItem;
