import { AdminAbilityType } from "@rakushifu/hrms-modules/dist/openapi";
import { useMemo } from "react";

import { useGateway } from "@/contexts/GatewayContext";
import { useApiQuery } from "@/hooks/useApiQuery";

import { USE_ORGANIZATIONS_QUERY_KEY } from "./useOrganizations";

export const useAbilities = () => {
  const gateway = useGateway();

  const { data } = useApiQuery([USE_ORGANIZATIONS_QUERY_KEY], () =>
    gateway.adminApiClient.getAdminCompanyOrganization()
  );

  const abilities = useMemo(() => data?.currentAdmin.abilities || [], [data]);

  const canReadCompany = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.CompanyRead),
    [abilities]
  );
  const canUpdateCompany = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.CompanyUpdate),
    [abilities]
  );
  const canCreateSection = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.SectionCreate),
    [abilities]
  );
  const canReadSection = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.SectionRead),
    [abilities]
  );
  const canUpdateSection = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.SectionUpdate),
    [abilities]
  );
  const canDeleteSection = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.SectionDelete),
    [abilities]
  );
  const canCreateStore = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.StoreCreate),
    [abilities]
  );
  const canReadStore = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.StoreRead),
    [abilities]
  );
  const canUpdateStore = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.StoreUpdate),
    [abilities]
  );
  const canDeleteStore = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.StoreDelete),
    [abilities]
  );
  const canCreateAdmin = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.AdminCreate),
    [abilities]
  );
  const canReadAdmin = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.AdminRead),
    [abilities]
  );
  const canUpdateAdmin = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.AdminUpdate),
    [abilities]
  );
  const canDeleteAdmin = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.AdminDelete),
    [abilities]
  );
  const canUpdateManagingStores = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.ManagingStoresUpdate),
    [abilities]
  );
  const canCreateEmployeeDataDefinition = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataDefinitionCreate),
    [abilities]
  );
  const canReadEmployeeDataDefinition = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataDefinitionRead),
    [abilities]
  );
  const canUpdateEmployeeDataDefinition = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataDefinitionUpdate),
    [abilities]
  );
  const canDeleteEmployeeDataDefinition = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataDefinitionDelete),
    [abilities]
  );
  const canCreateEmployee = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeCreate),
    [abilities]
  );
  const canReadEmployee = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeRead),
    [abilities]
  );
  const canUpdateEmployee = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeUpdate),
    [abilities]
  );
  const canDeleteEmployee = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDelete),
    [abilities]
  );
  const canCreateEmployeeDataAcquisitionForm = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataAcquisitionFormCreate),
    [abilities]
  );
  const canReadEmployeeDataAcquisitionForm = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataAcquisitionFormRead),
    [abilities]
  );
  const canUpdateEmployeeDataAcquisitionForm = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataAcquisitionFormUpdate),
    [abilities]
  );
  const canDeleteEmployeeDataAcquisitionForm = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeDataAcquisitionFormDelete),
    [abilities]
  );
  const canCreateEmployeeRequest = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeRequestCreate),
    [abilities]
  );
  const canReadEmployeeRequest = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeRequestRead),
    [abilities]
  );
  const canUpdateEmployeeRequest = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.EmployeeRequestUpdate),
    [abilities]
  );
  const canCreateContractTemplate = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.ContractTemplateCreate),
    [abilities]
  );
  const canReadContractTemplate = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.ContractTemplateRead),
    [abilities]
  );
  const canUpdateContractTemplate = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.ContractTemplateUpdate),
    [abilities]
  );
  const canDeleteContractTemplate = useMemo(
    () => !!abilities.find((a) => a.type === AdminAbilityType.ContractTemplateDelete),
    [abilities]
  );

  const canUpdateSectionOrder = useMemo(
    () =>
      !!abilities.find((a) => a.type === AdminAbilityType.SectionOrderUpdate) &&
      data?.currentAdmin.canOperateAllStores,
    [abilities, data]
  );
  const canUpdateStoreOrder = useMemo(
    () =>
      !!abilities.find((a) => a.type === AdminAbilityType.StoreOrderUpdate) &&
      data?.currentAdmin.canOperateAllStores,
    [abilities, data]
  );

  return {
    canReadCompany,
    canUpdateCompany,
    canCreateSection,
    canReadSection,
    canUpdateSection,
    canDeleteSection,
    canCreateStore,
    canReadStore,
    canUpdateStore,
    canDeleteStore,
    canCreateAdmin,
    canReadAdmin,
    canUpdateAdmin,
    canDeleteAdmin,
    canUpdateManagingStores,
    canCreateEmployeeDataDefinition,
    canReadEmployeeDataDefinition,
    canUpdateEmployeeDataDefinition,
    canDeleteEmployeeDataDefinition,
    canCreateEmployee,
    canReadEmployee,
    canUpdateEmployee,
    canDeleteEmployee,
    canCreateEmployeeDataAcquisitionForm,
    canReadEmployeeDataAcquisitionForm,
    canUpdateEmployeeDataAcquisitionForm,
    canDeleteEmployeeDataAcquisitionForm,
    canCreateEmployeeRequest,
    canReadEmployeeRequest,
    canUpdateEmployeeRequest,
    canCreateContractTemplate,
    canReadContractTemplate,
    canUpdateContractTemplate,
    canDeleteContractTemplate,
    canUpdateSectionOrder,
    canUpdateStoreOrder,
  };
};
