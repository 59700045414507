import axios from "axios";

import { ErrorResponse } from "@/gateways";

export const getErrorMessage = (error: any) => {
  const errorResponse =
    axios.isAxiosError(error) && error.response
      ? (error.response.data as ErrorResponse)
      : undefined;
  const backendErrorMessage = errorResponse?.detail;
  const invalidParams = errorResponse?.invalidParams;
  return {
    backendErrorMessage,
    invalidParams,
  };
};
