import { COLORS } from "@/styles/colors";

import { Stack, Text } from "@mantine/core";
import Image from "next/legacy/image";
import Link from "next/link";
import { Component, ErrorInfo, ReactNode } from "react";

import WhiteButton from "@/components/atoms/WhiteButton";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack pt={150} align="center">
          <Image
            src="/images/penguin.png"
            height={164}
            width={101}
            objectFit="contain"
            alt="らくしふ労務管理"
          />
          <Text align="center" size="lg" weight="bold" sx={{ color: COLORS.rakushifuBlue }}>
            一時的なエラーが発生しました
            <br />
            しばらく経ってからアクセスしてください
          </Text>
          <Link href="/employees" passHref legacyBehavior>
            <WhiteButton sx={{ width: 200 }}>らくしふ労務管理に戻る</WhiteButton>
          </Link>
        </Stack>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
