import { CommonApiInterface } from "@rakushifu/hrms-modules/dist/openapi";
import { ReactNode, createContext, useContext } from "react";

import { AdminApiInterface } from "@/gateways";

const GatewayContext = createContext<{
  adminApiClient: AdminApiInterface;
  commonApiClient: CommonApiInterface;
}>({
  adminApiClient: {} as AdminApiInterface,
  commonApiClient: {} as CommonApiInterface,
});

interface Props {
  children: ReactNode;
  adminApiClient: AdminApiInterface;
  commonApiClient: CommonApiInterface;
}

export const GatewayProvider = ({ children, adminApiClient, commonApiClient }: Props) => {
  return (
    <GatewayContext.Provider value={{ adminApiClient, commonApiClient }}>
      {children}
    </GatewayContext.Provider>
  );
};

export const useGateway = () => useContext(GatewayContext);
