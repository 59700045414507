import { V1AdminSignInResponse } from "@rakushifu/hrms-modules/dist/openapi";
import axios from "axios";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { HRMS_AUTH_HEADER_KEYS } from "@/constants";
import { AdminApiInterface } from "@/gateways";
import { useClientAuth } from "@/gateways/authStore";
import { getErrorMessage } from "@/utils/getErrorMessage";

interface CurrentUser {
  id: number;
  adminId: number;
  email: string;
}

interface Auth {
  isLoggedIn: boolean;
  isCheckedAuth: boolean;
  login: (email: string, password: string) => Promise<string | undefined>;
  logout: () => Promise<string | undefined>;
  currentUser: CurrentUser;
}

const AuthContext = createContext<Auth>({} as Auth);

interface Props {
  children: ReactNode;
  adminApiClient: AdminApiInterface;
}

export const AuthProvider = ({ children, adminApiClient }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckedAuth, setIsCheckedAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as CurrentUser);
  const clientAuth = useClientAuth();
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const { headers, data } = await axios.post<V1AdminSignInResponse>(
          "/api/auth/sign-in",
          { email, password },
          { withCredentials: true }
        );
        clientAuth?.setAuth({
          accessToken: headers[HRMS_AUTH_HEADER_KEYS.accessToken],
          uid: headers[HRMS_AUTH_HEADER_KEYS.uid],
          client: headers[HRMS_AUTH_HEADER_KEYS.client],
        });
        setCurrentUser({
          id: data.data.id,
          adminId: data.data.adminId,
          email: data.data.email,
        });
      } catch (e) {
        const { backendErrorMessage } = getErrorMessage(e);
        return backendErrorMessage || "メールアドレスまたはパスワードが正しくありません";
      }
      setIsLoggedIn(true);
    },
    [clientAuth]
  );

  const logout = useCallback(async () => {
    if (!clientAuth) {
      return;
    }
    try {
      await axios.delete("/api/auth/sign-out", {
        withCredentials: true,
        headers: {
          [HRMS_AUTH_HEADER_KEYS.accessToken]: clientAuth.accessToken,
          [HRMS_AUTH_HEADER_KEYS.client]: clientAuth.client,
          [HRMS_AUTH_HEADER_KEYS.uid]: clientAuth.uid,
        },
      });
      clientAuth?.removeAuth();
      setCurrentUser({} as CurrentUser);
      setIsLoggedIn(false);
    } catch (e) {
      const { backendErrorMessage } = getErrorMessage(e);
      return backendErrorMessage || "ログアウトに失敗しました";
    }
  }, [clientAuth]);

  const checkAuthOnServer = useCallback(async () => {
    try {
      if (clientAuth && clientAuth.accessToken && clientAuth.client && clientAuth.uid) {
        const res = await adminApiClient.validateTokenAsAdmin();
        setCurrentUser({
          id: res.data.data.id,
          adminId: res.data.data.adminId,
          email: res.data.data.email,
        });
        setIsLoggedIn(true);
      }
    } finally {
      setIsCheckedAuth(true);
    }
  }, [adminApiClient, clientAuth]);
  useEffect(() => {
    if (!isCheckedAuth) {
      checkAuthOnServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedAuth]);

  const auth: Auth = { isLoggedIn, isCheckedAuth, login, logout, currentUser };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
