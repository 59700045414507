import { COLORS } from "@/styles/colors";

import {
  faBuilding,
  faFileAlt,
  faIdBadge,
  faList,
  faSignOutAlt,
  faStore,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Center, Group, Header, Menu, MenuProps, Space, Stack } from "@mantine/core";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useCallback } from "react";

import NewTabLink from "@/components/molecules/NewTabLink";
import MenuLink from "@/components/organisms/GlobalNavigation/MenuLink";
import { useAuth } from "@/contexts/AuthContext";
import { useAbilities } from "@/hooks/useAbilities";
import { useOrganizations } from "@/hooks/useOrganizations";
import { showErrorNotification, showSuccessNotification } from "@/utils/notifications";

import MenuControl from "./MenuControl";
import MenuItem from "./MenuItem";

const menuProps: MenuProps = {
  withArrow: true,
  shadow: "lg",
  trigger: "click",
  offset: 12,
  position: "bottom-start",
};

const menuStyles = {
  arrow: {
    left: "20px !important",
    width: "9px",
    height: "9px",
  },
};

const GlobalNavigation = () => {
  const { logout } = useAuth();
  const router = useRouter();
  const handleClickLogout = useCallback(async () => {
    const errorMessage = await logout();
    if (errorMessage) {
      showErrorNotification(errorMessage);
    } else {
      showSuccessNotification("ログアウトしました");
      router.push("/sign-in");
    }
  }, [router, logout]);

  const {
    canReadEmployee,
    canCreateEmployee,
    canReadAdmin,
    canCreateAdmin,
    canReadEmployeeRequest,
    canReadContractTemplate,
    canReadEmployeeDataAcquisitionForm,
    canReadEmployeeDataDefinition,
    canReadStore,
    canReadSection,
    canReadCompany,
  } = useAbilities();

  const { currentAdmin } = useOrganizations();

  const currentUserName = currentAdmin?.name || "";

  const menus = [
    {
      targetLabel: "従業員",
      href: "/employees",
      displayable: canReadEmployee,
      items: [],
    },
    { targetLabel: "依頼", href: "/requests", displayable: canReadEmployeeRequest, items: [] },
    {
      targetLabel: "管理者",
      href: "/admins",
      displayable: canReadAdmin,
      items: [],
    },
    {
      targetLabel: "雛形・フォーム",
      items: [
        {
          label: "契約書雛形",
          icon: faFileAlt,
          href: "/contract-templates",
          displayable: canReadContractTemplate,
        },
        {
          label: "従業員情報取得フォーム",
          icon: faList,
          href: "/forms",
          displayable: canReadEmployeeDataAcquisitionForm,
        },
        {
          label: "従業員情報定義",
          icon: faIdBadge,
          href: "/categories",
          displayable: canReadEmployeeDataDefinition,
        },
      ],
    },
    {
      targetLabel: "組織",
      items: [
        { label: "店舗", icon: faStore, href: "/stores", displayable: canReadStore },
        { label: "事業部", icon: faUserCircle, href: "/sections", displayable: canReadSection },
        { label: "会社", icon: faBuilding, href: "/company", displayable: canReadCompany },
      ],
    },
  ];

  return (
    <Header height={40} sx={{ borderBottom: `2px solid ${COLORS.gray200} ` }}>
      <Group position="apart" noWrap sx={{ height: "100%" }}>
        <Center sx={{ height: "100%" }}>
          <Center sx={{ width: "155px", height: "100%" }}>
            <Image src="/images/logo.png" height={20} width={155} objectFit="contain" alt="logo" />
          </Center>
          {menus.map((menu, i) => (
            <Menu width={270} {...menuProps} styles={menuStyles} key={`menu-${i}`}>
              <Menu.Target key={`menu-target-${i}`}>
                {menu.href && !menu.items.length ? (
                  <MenuLink label={menu.targetLabel} href={menu.href} />
                ) : (
                  <MenuControl label={menu.targetLabel} items={menu.items} />
                )}
              </Menu.Target>
              {!!menu.items.length && (
                <Menu.Dropdown key={`menu-dropdown-${i}`}>
                  <Stack spacing={8} p={16} sx={{ borderRadius: 8 }}>
                    {menu.items?.map(
                      (item, j) =>
                        item.displayable && (
                          <MenuItem href={item.href} icon={item.icon} key={`menu-item-${j}`}>
                            {item.label}
                          </MenuItem>
                        )
                    )}
                  </Stack>
                </Menu.Dropdown>
              )}
            </Menu>
          ))}
        </Center>

        <Center sx={{ height: "100%" }}>
          <NewTabLink href="https://xbit.notion.site/98269ee1a5f043068165053bde18e076">
            マニュアルサイト
          </NewTabLink>
          <Space w={16} />
          <Menu width={180} {...menuProps} styles={menuStyles}>
            <Menu.Target>
              <MenuControl label={currentUserName ? `${currentUserName}さん` : ""} />
            </Menu.Target>
            <Menu.Dropdown>
              <Stack spacing={8} p={16} sx={{ borderRadius: 8 }}>
                <MenuItem onClickItem={handleClickLogout} icon={faSignOutAlt}>
                  ログアウト
                </MenuItem>
              </Stack>
            </Menu.Dropdown>
          </Menu>
        </Center>
      </Group>
    </Header>
  );
};

export default GlobalNavigation;
