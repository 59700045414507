import { COLORS } from "@/styles/colors";

import { Box, Center } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { forwardRef } from "react";

interface Props {
  label: string;
  href: string;
}

const MenuLink = forwardRef<HTMLDivElement, Props>(({ label, href, ...others }, ref) => {
  const router = useRouter();
  const active = router.pathname.startsWith(href);
  return (
    <Center
      ref={ref}
      sx={{
        height: "100%",
        fontSize: 14,
        cursor: "pointer",
        ...(active
          ? {
              color: COLORS.rakushifuBlue,
              fontWeight: "bold",
              borderBottom: `2px solid ${COLORS.rakushifuBlue}`,
              marginBottom: -4,
            }
          : {}),
      }}
      px={16}
      role="menu"
      aria-label={label}
      {...others}
    >
      <Link href={href}>
        <Box sx={active ? { marginTop: -2 } : undefined}>{label}</Box>
      </Link>
    </Center>
  );
});
MenuLink.displayName = "MenuLink";

export default MenuLink;
